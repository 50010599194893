<template>
  <div>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      grow
      @change="onTabChange"
      class="mb-6"
    >
      <v-tab v-for="item in tabs" :key="item.status">
        <img :src="item.img" alt="" style="width: 30px; margin-right: 15px" />
        {{ item.text }}
        <v-chip class="ma-2">
          {{ (counts && counts[item.status]) || 0 }}
        </v-chip>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, key) in tabs" :key="item.status">
        <v-card color="basil" flat v-if="tab == key">
          <v-simple-table
            fixed-header
            height="calc(100vh - 216px)"
            class="table-padding-2"
          >
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th role="columnheader" style="min-width: 80px">
                    <InputFilter
                      :label="$t('labels.code')"
                      :placeholder="$t('labels.code')"
                      name="code"
                      sort-name="code"
                      :sorting="filters.sort_by"
                      @onFilter="onFilterChange"
                      @onSort="onSortChange"
                    />
                  </th>
                  <th role="columnheader" style="min-width: 80px">
                    <SelectFilter
                      :options="typeOptions"
                      :label="$t('labels.classify')"
                      :placeholder="$t('labels.classify')"
                      name="type"
                      sort-name="type"
                      :sorting="filters.sort_by"
                      @onFilter="onFilterChange"
                      @onSort="onSortChange"
                    />
                  </th>
                  <th role="columnheader" style="min-width: 80px">
                    <SelectFilter
                      :options="departmentOptions"
                      :label="$t('labels.department')"
                      :placeholder="$t('labels.department')"
                      name="type"
                      sort-name="type"
                      :sorting="filters.sort_by"
                      @onFilter="onFilterChange"
                      @onSort="onSortChange"
                    />
                  </th>
                  <!-- <th role="columnheader" style="min-width: 80px">
                    <SelectWarehouse
                      :label="$t('labels.warehouse')"
                      :placeholder="$t('labels.warehouse')"
                      name="id_warehouse"
                      sort-name="warehouse_code"
                     
                      :sorting="filters.sort_by"
                      @onFilter="onFilterChange"
                      @onSort="onSortChange"
                    />
                  </th> -->
                  <th role="columnheader" style="min-width: 80px">
                    <SelectEmployee
                      :label="$t('labels.employee_create')"
                      :placeholder="$t('labels.employee_create')"
                      name="id_employee_create"
                      sort-name="employee_create_name"
                      :sorting="filters.sort_by"
                      @onFilter="onFilterChange"
                      @onSort="onSortChange"
                    />
                  </th>
                  <th role="columnheader" style="min-width: 80px">
                    <DateRangeFilter
                      :label="$t('labels.created_at')"
                      :placeholder="$t('labels.created_at')"
                      name="created_at"
                      sort-name="created_at"
                      :sorting="filters.sort_by"
                      @onFilter="onFilterChange"
                      @onSort="onSortChange"
                    />
                  </th>
                  <th role="columnheader" style="min-width: 80px">
                    <InputFilter
                      :label="$t('labels.tracking')"
                      :placeholder="$t('labels.tracking')"
                      name="order_tracking_id"
                      sort-name="order_tracking_id"
                      :default-value="filters.order_tracking_id"
                      :sorting="filters.sort_by"
                      @onFilter="onFilterChange"
                      @onSort="onSortChange"
                    />
                  </th>
                  <th role="columnheader" class="text-center">
                    <SelectFilter
                      :options="stateOptions"
                      :label="$t('labels.warehouse_state')"
                      :placeholder="$t('labels.warehouse_state')"
                      name="order_state"
                      sort-name="order_state"
                      :sorting="filters.sort_by"
                      @onFilter="onFilterChange"
                      @onSort="onSortChange"
                    />
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 100px"
                  >
                    <InputFilter
                      :label="$t('labels.delivery_status')"
                      :placeholder="$t('labels.delivery_status')"
                      name="delivery_partner_state"
                      sort-name="delivery_partner_state"
                      :sorting="filters.sort_by"
                      @onFilter="onFilterChange"
                      @onSort="onSortChange"
                    />
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 100px"
                  >
                    <SelectFilter
                      :options="deliveryStateOption"
                      :label="$t('labels.summary_status')"
                      :placeholder="$t('labels.summary_status')"
                      name="id_delivery_state"
                      sort-name="delivery_state_name"
                      :sorting="filters.sort_by"
                      @onFilter="onFilterChange"
                      @onSort="onSortChange"
                    />
                  </th>
                  <th role="columnheader" style="min-width: 80px">
                    <InputFilter
                      :label="$t('labels.delivery_order_id')"
                      :placeholder="$t('labels.delivery_order_id')"
                      name="order_delivery_id"
                      sort-name="order_delivery_id"
                      :sorting="filters.sort_by"
                      @onFilter="onFilterChange"
                      @onSort="onSortChange"
                    />
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 80px"
                  >
                    {{ $t("labels.content") }}
                  </th>
                  <th role="columnheader"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, key) in items"
                  :key="`r_${key}_${item.id}`"
                  class="text-center"
                >
                  <td>{{ item.code }}</td>
                  <td>{{ item.type_text }}</td>
                  <td>{{ item.department_text }}</td>
                  <!-- <td>{{ item.warehouse_code }}</td> -->
                  <td>{{ item.creator_name }}</td>
                  <td>{{ formatDateTime(item.created_at) }}</td>
                  <td>
                    <OrderTracking
                      v-if="item.order_tracking_id"
                      :tracking-id="item.order_tracking_id"
                    />
                  </td>
                  <td>{{ $t(`labels.order_status_${item.order_state}`) }}</td>
                  <td>{{ item.delivery_partner_state }}</td>
                  <td>{{ item.delivery_state_name }}</td>
                  <td>
                    {{ item.order_short_delivery_id || item.order_delivery_id }}
                  </td>
                  <td>
                    <RequestMessages
                      :item="item"
                      @sendNewMessage="sendNewMessage"
                      :key="`msg_${item.id}_${
                        (item.messages && item.messages.length) || 0
                      }`"
                    />
                  </td>
                  <td>
                    <v-btn
                      :title="
                        item.status === 'received' ? 'Nhận yêu cầu' : 'Tham gia'
                      "
                      color="warning"
                      class="mb-2"
                      outlined
                      v-if="isShowBtnJoin(item)"
                      @click="joinRequest(item.id)"
                    >
                      <img
                        src="@/assets/internal_requests/join.png"
                        alt=""
                        style="width: 30px"
                      />
                    </v-btn>

                    <v-btn
                      title="Chờ xác nhận"
                      color="info"
                      class="mb-2"
                      outlined
                      v-if="['processing'].includes(item.status)"
                      @click="updateStatus(item.id, 'waiting_response')"
                    >
                      <img
                        src="@/assets/internal_requests/3_Cho_xac_nhan.png"
                        alt=""
                        style="width: 30px"
                      />
                    </v-btn>

                    <v-btn
                      title="Hoàn thành"
                      color="success"
                      class="mb-2"
                      outlined
                      v-if="
                        ['processing', 'waiting_response'].includes(item.status)
                      "
                      @click="updateStatus(item.id, 'processed')"
                    >
                      <img
                        src="@/assets/internal_requests/4_Da_hoan_thanh.png"
                        alt=""
                        style="width: 30px"
                      />
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-row class="pt-3">
            <v-col cols="1">
              <!-- <v-btn color="success" @click="reportExport"><v-icon>mdi-download</v-icon> Excel</v-btn> -->
            </v-col>
            <v-col cols="10">
              <v-pagination
                v-model="page"
                :length="totalPage"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="1"> </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  GOODS_ISSUE_STATE,
  DELIVERY_STATE,
  REQUEST_TYPE_OPTIONS,
  REQUEST_DEPARTMENT_OTPIONS,
} from "@/libs/const";
import {
  debounce,
  downloadExcelFile,
  formatNumber,
  formatDateTime,
  getUrlParameter,
  isMobile,
} from "@/libs/helpers";

export default {
  name: "Index",
  components: {
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    // SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectEmployee: () => import("@/components/table/SelectEmployee"),
    OrderTracking: () => import("@/components/common/OrderTracking"),
    RequestMessages: () => import("@/components/order_request/Messages"),
  },
  data: () => ({
    tabs: [
      {
        text: "Đã tiếp nhận",
        status: "received",
        img: require("@/assets/internal_requests/1_Cho_xu_Ly.png"),
      },
      {
        text: "Đang xử lý",
        status: "processing",
        img: require("@/assets/internal_requests/2_Dang_xu_ly.png"),
      },
      {
        text: "Chờ xác nhận",
        status: "waiting_response",
        img: require("@/assets/internal_requests/3_Cho_xac_nhan.png"),
      },
      {
        text: "Đã xử lý",
        status: "processed",
        img: require("@/assets/internal_requests/4_Da_hoan_thanh.png"),
      },
    ],
    filters: {
      status: "received",
    },
    tab: 0,
    page: 1,
    totalPage: 1,
    counts: {},
    items: [],
    isLoading: false,
    dialogRequest: false,
    typeOptions: [...REQUEST_TYPE_OPTIONS],
    departmentOptions: [...REQUEST_DEPARTMENT_OTPIONS],
    stateOptions: [...GOODS_ISSUE_STATE],
    deliveryStateOption: [...DELIVERY_STATE],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getUrlParameter,
    downloadExcelFile,
    formatNumber,
    formatDateTime,
    isMobile,
    isShowBtnJoin(item) {
      return (
        ["received", "processing", "waiting_response"].includes(item.status) &&
        !item.request_member_ids.includes(`Ω${window.me.identity_id}Ω`)
      );
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },

    getList: debounce(function () {
      httpClient
        .post("/order-request/v1/list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.counts = { ...data.counts };
          this.totalPage = data.totalPage;
          this.items = [...data.rows].map((item) => {
            const type = this.typeOptions.find((t) => t.value == item.type);
            item.type_text = (type && type.text) || null;
            const department = this.departmentOptions.find(
              (t) => t.value == item.incharge_department
            );
            item.department_text = (department && department.text) || null;
            return item;
          });
          this.items.forEach((item, key) => {
            this.getDetail(item.id, key);
          });
        });
    }, 500),

    async getDetail(id, key) {
      const { data } = await httpClient.post("/order-request/v1/detail", {
        id,
      });
      const items = [...this.items];
      items[key].messages = data;
      this.items = [...items];
    },

    async sendNewMessage(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post("/order-request/v1/add-content", {
          id: item.id,
          content: item.content,
          file: item.file,
        });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.warning(errMsg);
      }
    },

    showDialogRequest() {
      this.dialogRequest = true;
    },
    closeDialogRequest() {
      this.dialogRequest = false;
    },
    async updateStatus(id, status) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/order-request/v1/change-status", {
          id,
          status,
        });
        await this.getList();
        this.isLoading = false;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.isLoading = false;
        alert(errMsg);
      }
    },

    async joinRequest(id) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/order-request/v1/join", { id });
        await this.getList();
        this.isLoading = false;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.isLoading = false;
        alert(errMsg);
      }
    },

    onTabChange(tab) {
      const filters = {};
      this.filters = { ...filters };
      this.filters.status = this.tabs[tab].status;
    },

    listenSocket() {
      window.socket.on("order_request_new_message", (data) => {
        const items = [...this.items];
        items.forEach((i, k) => {
          if (i.id === data.request_id) {
            const itemMsgs = items[k].messages || [];
            itemMsgs.push(data);
            items[k].messages = itemMsgs;
          }
        });
        this.items = [...items];
      });
      window.socket.on("order_request_status_changed", (data) => {
        if ([data.newStatus, data.oldStatus].includes(this.filters.status)) {
          this.getList();
        }
      });
    },
    async reportExport() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.waiting_file_download"));
        return false;
      }
      this.isLoading = true;
      const filename = `yeu-cau-xu-ly.xlsx`;

      try {
        await this.downloadExcelFile(
          "/internal-request/v1/report-export",
          this.filters,
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    const filters = { ...this.filters };
    const order_tracking_id = this.getUrlParameter("order_tracking_id");
    if (order_tracking_id) {
      filters.order_tracking_id = order_tracking_id;
    }
    const status = this.getUrlParameter("status");
    if (status) {
      filters.status = status;
      this.tab = status - 1;
    }
    this.filters = { ...filters };
    await this.getList();
    setTimeout(() => {
      this.listenSocket();
    }, 1000);
  },
};
</script>

<style scoped></style>
